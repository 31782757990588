import React, { useEffect, useState } from 'react';
import { Selection, VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine, VictoryTooltip } from 'victory';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import {
	Accordion,
	Button,
	ChartLegend,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
} from 'components';
import { BlockUtilizationObject, useAppSelector, useGetBlockUtilizationQuery, useSystem } from 'store';
import { border, fontFamily, getColor } from 'utils';
import { useFilters, useToast } from 'context';

const legend = [
	{
		label: 'Needs Attention',
		color: getColor('red'),
	},
	{
		label: 'Off Target',
		color: getColor('yellow'),
	},
	{
		label: 'On Target',
		color: getColor('green'),
	},
];

const sortByOptions = [
	{
		label: 'Utilization (low-high)',
		value: 'rate_ascending',
	},
	{
		label: 'Utilization (high-low)',
		value: 'rate_descending',
	},
	{
		label: 'Volume (low-high)',
		value: 'volume_ascending',
	},
	{
		label: 'Volume (high-low)',
		value: 'volume_descending',
	},
	{
		label: 'Allocation (low-high)',
		value: 'allocation_ascending',
	},
	{
		label: 'Allocation (high-low)',
		value: 'allocation_descending',
	},
	{
		label: 'Alphabetical (a-z)',
		value: 'alphabetical_ascending',
	},
	{
		label: 'Alphabetical (z-a)',
		value: 'alphabetical_descending',
	},
];

const groupByOptions = [
	{
		label: 'None',
		value: 'none',
	},
	{
		label: 'Day of Week',
		value: 'day_of_week',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: 'Quarter',
		value: 'quarter',
	},
	{
		label: 'Year',
		value: 'year',
	},
];

const viewByOptions = [
	{
		label: 'Day of Week',
		value: 'day_of_week',
	},
	{
		label: 'Block Name',
		value: 'block_name',
	},
];

export function BlockUtilizationOverview() {
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);

	// Filters
	const {
		dateRange,
		daysOfWeek,
		blockNames,
		blockTimeslot,
		utilizationType,
		turnoverTimeThreshold,
		abandonedDaysToggle,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
	} = useFilters();

	// default: view by month
	const selectedViewBy = dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy : viewByOptions[1];
	// default: sort by time, descending (newest data -> oldest data)
	const selectedSortBy = dropDowns.sortBy.value !== 'undefined' ? dropDowns.sortBy : sortByOptions[3];
	// default: group by year
	const selectedGroupBy = dropDowns.groupBy.value !== 'undefined' ? dropDowns.groupBy : groupByOptions[0];
	// default is view by Block Name, sort by Volume, group by None
	let filteredSortByOptions = sortByOptions.slice(0, sortByOptions.length);
	let filteredGroupByOptions = groupByOptions;

	// if viewby is changed to day of week, we need to remove day of week from group by
	// also need to remove alphabetical sort option
	if (selectedViewBy.value === 'day_of_week') {
		const gpOptions = groupByOptions.filter((option) => option.value !== 'day_of_week');
		filteredGroupByOptions = gpOptions;
		//setSelectedGroupBy(gpOptions[0]);
		const sbOptions = sortByOptions.slice(0, sortByOptions.length - 2);
		filteredSortByOptions = sbOptions;
		//setSelectedSortBy(sbOptions[0]);
	} else {
		filteredGroupByOptions = groupByOptions;
		//setSelectedGroupBy(groupByOptions[0]);
		filteredSortByOptions = sortByOptions;
		//setSelectedSortBy(sortByOptions[0]);
	}

	const { data: userData } = useSystem();
	const blockUtilPercentage =
		userData?.facilities?.find((f) => f.id === selectedFacility)?.block_utilization_target ?? 70;

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/block-utilization-overview') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const {
		data: blockOverviewData,
		isFetching,
		error,
	} = useGetBlockUtilizationQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			sortby: selectedSortBy.value,
			groupby: selectedGroupBy.value,
			viewby: selectedViewBy.value,
			filters: {
				days_of_week: daysOfWeek?.applied,
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				block_names: blockNames.applied,
				utilization_type: utilizationType.applied,
				block_timeslot: blockTimeslot.applied,
				turnover_threshold: turnoverTimeThreshold?.applied,
				abandoned_days_toggle: abandonedDaysToggle.applied,
			},
		},
		{
			skip: skipRequest,
		}
	);

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);

	let exportData = [];
	if (blockOverviewData?.data) {
		exportData = blockOverviewData.data
			.map((group) => {
				const items = group.values.map((v) => {
					// we need any for exporting here
					// eslint-disable-next-line
					const row: any = {
						value: v.y,
					};
					const key = selectedViewBy.value.replace('_id', '');
					row[key] = v.x;
					if (selectedGroupBy.value !== 'none') {
						row[selectedGroupBy.value] = group.groupValue;
					}
					return row;
				});

				return items;
			})
			.flat();
	}

	const data = blockOverviewData?.data ?? [];

	const isGrouped = selectedGroupBy?.value !== 'none';

	const abandonedDaysToggleState = abandonedDaysToggle.selected === 'Excluded';

	return (
		<div className='relative container mx-auto mb-32'>
			<div className='flex justify-between'>
				<PageHeading>Block Utilization Overview</PageHeading>
			</div>

			<div className='relative'>
				{(isFetching || skipRequest) && <LogoOverlay backgroundColor='white' />}
				<Panel
					title='Block Utilization'
					tooltipContent={`Use this visualization to determine block utilization performance by block owner. In general, higher block utilization performance correlates with more efficient resource usage and greater scheduling predictability.`}
					goToHelpID={'blockutilization'}
					headerContentCenter={
						abandonedDaysToggleState && (
							<div className='bg-yellow-100 flex p-2 rounded-md mt-2 justify-between py-3 items-center'>
								<span className='material-symbols-outlined text-yellow-500 pr-2'>warning</span>
								<p className='text-p3 text-gray-500'>
									<strong>Note:</strong> Utilization does not include abandoned days and may be higher than expected
								</p>
							</div>
						)
					}
					headerContentRight={
						<>
							<div className='flex justify-end pb-3'>
								<Drawer
									metadata={metadata}
									filtersAreDirty={filtersAreDirty}
									trigger={
										<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
											<span className='material-symbol'>filter_alt</span>
											Filters
										</Button>
									}
									quickActions={[
										{
											icon: 'undo',
											onClick: resetFilters,
											tooltipText: 'Discard unapplied filter changes',
											disabled: !filtersAreDirty,
										},
										{
											icon: 'restart_alt',
											onClick: clearFilters,
											tooltipText: 'Reset filters to default',
											disabled: !metadata.saved_at,
										},
									]}
									actionButtons={[
										{
											onClick: applyFilters,
											children: 'Apply',
											disabled: !filtersAreDirty,
										},
										{
											// eslint-disable-next-line @typescript-eslint/no-empty-function
											onClick: () => {},
											children: 'Views',
											disabled: false,
										},
									]}
								>
									<FilterFields
										fields={[
											'dateRange',
											'daysOfWeek',
											'blockNames',
											'blockTimeslot',
											'utilizationType',
											'turnoverTimeThreshold',
											'abandonedDaysToggle',
										]}
									/>
								</Drawer>
								<div>
									<ExportButton contents={exportData} />
								</div>
							</div>
						</>
					}
					isEmpty={blockOverviewData?.data?.length === 0}
				>
					<div className='flex justify-between items-center'>
						<div className='flex gap-4'>
							<div className='w-36'>
								<Select
									label='View by'
									onChange={(selected) => {
										if (selected) {
											const optionInViewBy = viewByOptions.find((element) => element.value === selected.value);
											if (optionInViewBy) {
												let newGroupBy;
												let newSortBy;
												if (selectedViewBy.value === 'day_of_week') {
													const gpOptions = groupByOptions.filter((option) => option.value !== 'day_of_week');
													newGroupBy = gpOptions[0];
													const sbOptions = sortByOptions.slice(0, sortByOptions.length - 2);
													newSortBy = sbOptions[0];
												} else {
													newGroupBy = groupByOptions[0];
													newSortBy = sortByOptions[0];
												}
												dropDowns.update({ viewBy: optionInViewBy, groupBy: newGroupBy, sortBy: newSortBy });
												saveDropdown({ viewBy: optionInViewBy, groupBy: newGroupBy, sortBy: newSortBy });
											}
										}
									}}
									value={selectedViewBy}
									options={viewByOptions}
									defaultValue={selectedViewBy}
								/>
							</div>
							<div className='w-64'>
								<Select
									label='Sort by'
									options={filteredSortByOptions}
									value={selectedSortBy}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, sortBy: selected });
											saveDropdown({ ...dropDowns, sortBy: selected });
										}
									}}
								/>
							</div>
							<div className='w-36'>
								<Select
									label='Group by'
									options={filteredGroupByOptions}
									value={selectedGroupBy}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, groupBy: selected });
											saveDropdown({ ...dropDowns, groupBy: selected });
										}
									}}
								/>
							</div>
						</div>
						<div>
							<ChartLegend options={legend} />
						</div>
					</div>

					{isGrouped && blockOverviewData?.data ? (
						<Accordion className='my-8' type='multiple' defaultValue={data.map((group) => `${group.groupValue}`)}>
							{data.map((group, i) => {
								return (
									<Accordion.Item key={i} value={`${group.groupValue}`} dark>
										<BlockUtil values={group.values} target={blockUtilPercentage} />
									</Accordion.Item>
								);
							})}
						</Accordion>
					) : (
						data.map((group, i) => {
							return (
								<BlockUtil
									key={`${selectedGroupBy.value}_${selectedSortBy.value}_${i}`}
									values={group.values}
									target={blockUtilPercentage}
								/>
							);
						})
					)}
				</Panel>
			</div>
		</div>
	);
}

export default BlockUtilizationOverview;

interface BlockUtilsProps {
	values: BlockUtilizationObject['values'];
	target: number;
}

// determines vertical spacing between bars
function determineHeight(valuesLength: number) {
	if (valuesLength <= 5) {
		return 40 * valuesLength;
	} else if (valuesLength > 5) {
		return 25 * valuesLength;
	} else {
		return 28 * valuesLength;
	}
}

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: {
		block_utilization: string;
		in_block_minutes: number;
		block_turnover_minutes: number;
		allocated_minutes: number;
		y: number;
		x: string;
	};
}> {
	render() {
		const { x2, y2, datum } = this.props;
		return (
			<foreignObject x={x2} y={y2} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-20'>
					<div className='bg-white p-1 px-3 pl-1'>
						<p className='text-left text-[0.27em] font-semibold'>{datum?.x}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Block Utilization</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.y}%</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>In Block Minutes</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.in_block_minutes}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Turnover Minutes</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.block_turnover_minutes}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Allocated Minutes</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.allocated_minutes}</p>
					</div>
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	datum?: {
		block_utilization: string;
		in_block_minutes: number;
		block_turnover_minutes: number;
		allocated_minutes: number;
		y: number;
		x: string;
	};
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							// The label will not change position, but the tooltip will change position
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2 } = this.props;
		return (
			<g>
				<VictoryLabel {...this.props} verticalAnchor='middle' style={{ fontFamily: fontFamily, fontSize: 5 }} />
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} />}
					style={{ fontSize: 0 }}
				/>
			</g>
		);
	}
}

function BlockUtil({ values, target }: BlockUtilsProps) {
	const valuesAxis = {
		axis: { stroke: border },
		tickLabels: { fontFamily: fontFamily, fontSize: 5, padding: 5 },
	};

	const viewByAxis = {
		axis: { stroke: border },
		grid: { stroke: border },
		tickLabels: { fontFamily: fontFamily, fontSize: 5, padding: 3 },
	};

	const dataMaxY = (values && Math.ceil(Math.max(...values.map((d) => d.y)) + 10)) ?? 100;
	const avg = Math.round(values.reduce((r, c) => r + c.x.toString().length, 0) / values.length);
	const x = 17;
	const regx = new RegExp(`^.{${x}}`, 'g');
	// This is used to find block_id for a block based on name (used to link to block scorecard)
	const { blockNames } = useFilters();
	const block_name_map: { [key: string]: number } = {};
	blockNames.all.forEach((block) => {
		block_name_map[block.name] = block.id;
	});

	const navigate = useNavigate();

	return (
		<VictoryChart
			height={determineHeight(values.length)}
			padding={{ top: 15, bottom: 15, left: avg > 20 ? avg * 2.8 : 65, right: 10 }}
			domain={{ y: [0, dataMaxY < 100 ? 100 : dataMaxY] }}
			horizontal
			domainPadding={10}
		>
			<VictoryAxis offsetY={12} dependentAxis style={viewByAxis} tickFormat={(t) => `${t}%`} orientation='top' />
			<VictoryAxis style={valuesAxis} tickFormat={(t) => `${t.length > 20 ? t.match(regx) + '...' : t}`} />
			<VictoryBar
				data={values}
				// we noticed VictoryBar was not preserving the sort order from the endpoint so we are sorting values here
				// we are sorting values one way in the backend and sorting again here...maybe we can get around this behavior in the future
				sortKey='x'
				sortOrder='descending'
				cornerRadius={2}
				barWidth={12.5}
				style={{
					data: {
						fill: ({ datum }) => {
							const value = datum.y;
							if (value < 40) {
								return getColor('red');
							} else if ((value >= 40 && value < target) || value < 85) {
								return getColor('yellow');
							} else {
								return getColor('green');
							}
						},
						cursor: 'pointer',
					},
				}}
				labels={values.map((v) => `${v.y}%`)}
				labelComponent={<CustomLabel />}
				events={[
					{
						target: 'data',
						eventHandlers: {
							onClick: () => {
								return [
									{
										target: 'data',
										mutation: (props) => {
											// eslint-disable-next-line react/prop-types
											const blockName = props.datum.x;
											navigate(`/block-scorecard?block_scorecard_id=${block_name_map[blockName]}`);
										},
									},
								];
							},
							onMouseEnter: () => {
								return [
									{
										target: 'data',
										mutation: (props) => {
											// eslint-disable-next-line react/prop-types
											props.style.opacity = 0.8;
											return props;
										},
									},
								];
							},
							onMouseLeave: () => {
								return [
									{
										target: 'data',
										mutation: (props) => {
											// eslint-disable-next-line react/prop-types
											props.style.opacity = 1;
											return props;
										},
									},
								];
							},
						},
					},
				]}
			/>
			<VictoryLine
				y={() => target}
				style={{
					data: { stroke: '#000033', strokeWidth: 0.25, strokeDasharray: 1 },
					parent: { border: '0.5px solid #ccc' },
				}}
			/>
			<VictoryLabel
				text={'Target - ' + target.toString() + ' %'}
				x={4 * target + 32}
				y={10}
				style={{ fontFamily: fontFamily, fontSize: 5, fontWeight: 700 }}
			/>
		</VictoryChart>
	);
}
